.report-popup{
width: 100vw;
min-height: 100vh;
position: fixed;
top: 0px;
left: 0px;
background-color: rgba(0, 0, 0, 0.606);
z-index: 9999;
padding-bottom: 100px;
overflow: scroll;
}

.temp{
    height: 700px;
    overflow-y: scroll;
    padding: 5px 10px;
    background-color: #98ccec;
    width: 800px;
    margin:auto;
    margin-top: 50px;
}

.temp::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .temp::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .temp::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  .temp::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
.report-popup .container{
    background: #fff;
    min-height: 1000px;
    margin-top:3%;
    padding: 30px;
    max-width: 1000px;
    margin-bottom: 200px;
}


.copy-btn{
   position: absolute;
   right:20px;
   top: 10px; 
   box-shadow: 1px 1px 2px grey;
}