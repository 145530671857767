#area p {
  margin-bottom: 2px !important;
  line-height: 25px;
}

#area {
  width: max-content;
  min-width:800px;
}

.box {
  border: 0.5px solid gray;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 20px;
  margin-bottom: 20px;
}
